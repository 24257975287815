@keyframes reveal-border {
    12.5%, 100% {
        opacity: 1; 
    }
}

@keyframes reveal-circle {
    0%, 25% {
        stroke-dasharray: 0, 750;
        stroke-opacity: 0;
    }
    50%, 100% {
        stroke-dasharray: 650, 100;
    }
    25.1%, 100% {
        stroke-opacity: 1;
    }
}

@keyframes reveal-leaf1 {
    0%, 43.75% {
        stroke-dasharray: 0, 430;
    }
    81.25%, 100% {
        stroke-dasharray: 430, 0;
    }
}

@keyframes reveal-leaf2 {
    0%, 75% {
        stroke-dasharray: 0, 200;
    }
    100% {
        stroke-dasharray: 200, 0;
    }
}

@keyframes shift {
    0%, 37.5% {
        stroke-dashoffset: 700;
    }
    56.25%, 100% {
        stroke-dashoffset: 620;
    }
}

#circle-path {
    stroke: none;
    stroke-width: 0.999212;
    stroke-dasharray: none;
}
#circle-fill {
    stroke: #ffffff;
    stroke-width: 19.0701;
    stroke-linecap: round;
    stroke-opacity: 0;
    stroke-dasharray: 0, 750;
    stroke-dashoffset: 700;
}
.animate.loop#circle-fill {
    animation: reveal-circle 4s ease-in-out infinite alternate,
               shift 4s ease-in-out infinite alternate;
}
.animate.onetime#circle-fill {
    animation: reveal-circle 4s ease-in-out forwards,
               shift 4s ease-in-out forwards;
}

#circle-2-path {
    stroke: none;
    stroke-width: 0.999212;
    stroke-dasharray: none;
}

#leaf1-path {
    stroke: none; 
    stroke-width: 0.999212;
    stroke-dasharray: none;
    stroke-opacity: 1;
}

#leaf1-fill {
    stroke: #ffffff;
    stroke-width: 58;
    stroke-linecap: round;
    stroke-opacity: 1;
    stroke-dasharray: 0, 430;
    /* stroke-dashoffset: 700; */
}
.animate.loop#leaf1-fill {
    animation: reveal-leaf1 4s ease-in-out infinite alternate;
}
.animate.onetime#leaf1-fill {
    animation: reveal-leaf1 4s ease-in-out forwards;
}

#leaf2-path {
    stroke: none;
    stroke-width: 0.999212;
    stroke-dasharray: none;
    stroke-opacity: 1;
}

#leaf2-fill {
    stroke: #ffffff;
    stroke-width: 33.1618;
    stroke-linecap: round;
    stroke-opacity: 1; 
    stroke-dasharray: 0, 200;
    /* stroke-dashoffset: 700; */
}
.animate.loop#leaf2-fill {
    animation: reveal-leaf2 4s ease-in-out infinite alternate;
}
.animate.onetime#leaf2-fill {
    animation: reveal-leaf2 4s ease-in-out forwards;
}

.animate #border {
    fill: url(#linearGradient2);
    fill-opacity: 1;
    opacity: 0;
    stroke: url(#linearGradient1);
    stroke-width: 0.999212;
    stroke-dasharray: none; 
    animation: reveal-border 4s ease-in-out forwards;
}

#stop1 {
    stop-color: #123635;
    stop-opacity: 1;
}

#stop2 {
    stop-color: #072527;
    stop-opacity: 1;
}