@keyframes scroll-left {
    0%, 15% {
        transform: translateX(calc(0px - (var(--containerWidth) / 2) - (0 * var(--iconWidth))));
    }
    20%, 35% {
        transform: translateX(calc(0px - (var(--containerWidth) / 2) - (1 * var(--iconWidth))));
    }
    40%, 55% {
        transform: translateX(calc(0px - (var(--containerWidth) / 2) - (2 * var(--iconWidth))));
    }
    60%, 75% {
        transform: translateX(calc(0px - (var(--containerWidth) / 2) - (3 * var(--iconWidth))));
    }
    80%, 95% {
        transform: translateX(calc(0px - (var(--containerWidth) / 2) - (4 * var(--iconWidth))));
    }
    100% {
        transform: translateX(calc(0px - (var(--containerWidth) / 2) - (5 * var(--iconWidth))));
    }
}

.lifecycle-animation {
    animation: scroll-left 20s ease-in-out infinite;
}
  
.lifecycle-icon {
    max-width: 100px;
    height: auto;
}

.active {
    opacity: 1;
}

.lifecycle-text:after {
    animation: lifecycleText 20s linear infinite;
    content: 'Agriculture';
}

@media only screen and (min-width: 768px) {
    .cutout-desktop {
        clip-path: polygon(0 100%, 0 0, 10% 70%, 100% 0, 100% 100%) !important;
    }
}